import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { fetchReferralLink } from '../utils/referralLink';
import { sendGreeting } from '../utils/sendGreeting';
import FloatingPoint from './FloatingPoint';
import FooterBar from './FooterBar'; // Import FooterBar
import PopupNotification from './PopupNotification';


import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';



const Home = () => {
    const frontendUrl = "https://bot.ethernity.game";

    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
    const telegramId = telegramUser ? telegramUser.id : 'unknown';
    const [initData, setInitData] = useState(window.Telegram.WebApp.initData || '');

    useEffect(() => {
        if (window.Telegram.WebApp.initData) {
            setInitData(window.Telegram.WebApp.initData);
        }
    }, []);

    const [user, setUser] = useState({
        tokens: 0,
        miningTime: 0,
        invitedCount: 0,
        firstLevelInvitedCount: 0,
        secondLevelInvitedCount: 0,
        thirdLevelInvitedCount: 0,
        firstLevelTokens: 0,
        secondLevelTokens: 0,
        thirdLevelTokens: 0,
        miningMultiplier: 0.1,
        walletAddress: '',
    });

    const [timer, setTimer] = useState(null);
    const navigate = useNavigate();
    const [animationTrigger, setAnimationTrigger] = useState(0);
    const [backgroundIndex, setBackgroundIndex] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const latestApiCall = useRef(null);

    const defaultNft = {
        tokenId: '0',
        name: 'DEFAULT SKIN',
        image: 'https://bot.ethernity.game/new-bg.jpg',
        attributes: [
            { trait_type: 'rarity', value: 0 },
            { trait_type: 'multiplier', value: 0 },
            { trait_type: 'bonus', value: 0 }
        ]
    };
    
    const fetchUserData = async (retries = 3, delay = 1000) => {
        if (latestApiCall.current) {
            return;
        }

        latestApiCall.current = true;

        try {
            setIsLoading(true);

            // Fetch user info
            const response = await axios.post('/api/user-info', { initData }); // Pass initData to the API call
            let userData = response.data;
            // console.log('User Data:', userData);

            if (!userData.walletAddress) {
                userData.equippedNFT = {
                    tokenId: defaultNft.tokenId,
                    tokenURI: defaultNft.image,
                    multiplier: 0,
                    name: 'Ava',
                    age: '19',
                    race: 'Human',
                    profession: 'Avatar',
                    bio: 'Ava is your best friend and guide in the world of Ethernity. She is always there to help you and provide valuable advice.'
                };

                // Update on the server if necessary
                await axios.post('/api/equip-nft', {
                    telegramId,
                    tokenId: userData.equippedNFT.tokenId,
                    tokenURI: userData.equippedNFT.tokenURI,
                    multiplier: userData.equippedNFT.multiplier,
                    name: userData.equippedNFT.name,
                    age: userData.equippedNFT.age,
                    race: userData.equippedNFT.race,
                    profession: userData.equippedNFT.profession,
                    bio: userData.equippedNFT.bio
                });
            }

            if (userData.walletAddress) {
                // Fetch owned NFTs
                try {
                    const nftsResponse = await axios.get(`/api/get-owned-nfts?telegramId=${telegramId}`);
                    console.log('NFTs Response:', nftsResponse);
                } catch (nftError) {
                    console.error('Failed to fetch owned NFTs:', nftError);
                    // Continue execution even if fetching NFTs fails
                }

                // Ensure equippedNFT exists and handle if it's undefined or if tokenId is 0
                if (userData.equippedNFT && userData.equippedNFT.tokenId !== 0) {
                    if (!userData.equippedNFT || userData.equippedNFT.tokenId !== defaultNft.tokenId) {
                        if (!userData.equippedNFT || !userData.ownedNFTs.some(nft => nft.tokenId === userData.equippedNFT.tokenId)) {
                            console.log('Equipped NFT not found in owned NFTs or is undefined:', userData.equippedNFT);
                            // Unequip and use default NFT
                            userData.equippedNFT = {
                                tokenId: defaultNft.tokenId,
                                tokenURI: defaultNft.image,
                                multiplier: 0,
                                name: 'Ava',
                                age: '19',
                                race: 'Human',
                                profession: 'Avatar',
                                bio: 'Ava is your best friend and guide in the world of Ethernity. She is always there to help you and provide valuable advice.'
                            };

                            // Update on the server if necessary
                            await axios.post('/api/equip-nft', {
                                telegramId,
                                tokenId: userData.equippedNFT.tokenId,
                                tokenURI: userData.equippedNFT.tokenURI,
                                multiplier: userData.equippedNFT.multiplier,
                                name: userData.equippedNFT.name,
                                age: userData.equippedNFT.age,
                                race: userData.equippedNFT.race,
                                profession: userData.equippedNFT.profession,
                                bio: userData.equippedNFT.bio
                            });
                        }
                    }
                }
            }

            setUser(userData);
            setIsLoading(false);
        } catch (error) {
            console.error('Failed to fetch user info:', error);
            if (error.response && error.response.status === 404) {
                setIsLoading(false);
            } else if (retries > 0) {
                setTimeout(() => fetchUserData(retries - 1, delay), delay);
            } else {
                setIsLoading(false);
            }
        } finally {
            latestApiCall.current = false;
        }
    };







    useEffect(() => {
        const ws = new WebSocket('wss://telegram-web-app-backend.azurewebsites.net');

        ws.onopen = () => {
            console.log('WebSocket connection established');
        };

        ws.onmessage = async (event) => {
            if (event.data instanceof Blob) {
                // Convert Blob to text
                const text = await event.data.text();
                try {
                    const message = JSON.parse(text);
                    console.log('Parsed WebSocket message:', message);
                    if (message.type === 'UPDATE_NFT_LIST') {
                        fetchUserData(); // Call refetchData when an update message is received
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = () => {
            console.log('WebSocket connection closed');
        };

        return () => {
            ws.close();
        };
    }, []);



    useEffect(() => {
        fetchUserData();

        if (timer) {
            clearInterval(timer);
        }

        const interval = setInterval(() => {
            setUser((prevState) => {
                if (prevState.miningTime > 0) {
                    setAnimationTrigger(prev => prev + 1);
                    setBackgroundIndex((prevIndex) => (prevIndex === 1 ? 2 : 1));

                    return {
                        ...prevState,
                        miningTime: Math.max(prevState.miningTime - 1, 0),
                        tokens: prevState.tokens + prevState.miningMultiplier,
                    };
                } else if (prevState.miningTime <= 0) {
                    setBackgroundIndex(1);
                    return prevState;
                } else {
                    return prevState;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [telegramId]);

    const updatePinataUrl = (url) => {
        const pinataGatewayToken = "_F-lMKYPVKRdRfyrP7Lyv_mUDjQGEm-bFc_BLdb3VtFdifoZp7Mruw5je8WgLMoR";
        const pinataGatewayPrefix = "https://gateway.pinata.cloud/ipfs/";
        const newPrefix = "https://myethernity.mypinata.cloud/ipfs/";

        if (url.startsWith(pinataGatewayPrefix)) {
            const cid = url.split(pinataGatewayPrefix)[1];
            return `${newPrefix}${cid}?pinataGatewayToken=${pinataGatewayToken}`;
        }

        return url;
    };

    const equippedTokenURI = user.equippedNFT?.tokenURI || "https://bot.ethernity.game/new-bg.jpg";
    const equippedTokenURIWithPinata = updatePinataUrl(equippedTokenURI);



    const [clicks, setClicks] = useState(0);
    const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();


    const syncClicksToBackend = async (clicksToSend) => {
        console.log(`Sending ${clicksToSend} clicks to backend...`);

        try {
            const response = await axios.post('/api/add-mining-time', {
                initData,
                additionalTime: clicksToSend * 60 * 60, // Convert clicks to seconds (each click adds 60 minutes)
            });

            setUser(response.data);
            console.log('Backend response:', response.data);
        } catch (error) {
            console.error('Failed to sync clicks to backend:', error);
        }
    };

    const handleBulletClick = () => {
        setClicks(prevClicks => prevClicks + 1);

        setUser(prevState => {
            const newMiningTime = prevState.miningTime + 60 * 60; // Add 60 minutes (3600 seconds) per click
            return {
                ...prevState,
                miningTime: Math.min(newMiningTime, 36000), // Ensure it doesn't exceed 10 hours
            };
        });

        impactOccurred('heavy'); // Use haptic feedback instead of navigator.vibrate


        console.log(`Click recorded. Total clicks: ${clicks + 1}`);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (clicks > 0) {
                console.log(`Syncing ${clicks} clicks to backend...`);
                syncClicksToBackend(clicks);
                setClicks(0);
            }
        }, 1 * 1000); // Sync every 1 second 

        return () => clearInterval(intervalId);
    }, [clicks]);

    const bullets = [];
    const maxBullets = 9;
    const remainingHours = user.miningTime / 3600;
    const fullBullets = Math.ceil(remainingHours);

    for (let i = 0; i < maxBullets; i++) {
        if (i < fullBullets) {
            bullets.push(
                <img
                    key={i}
                    className="bullet-image"
                    src={`${frontendUrl}/bullet-1.png`}
                    alt="Full Bullet"
                    style={{ cursor: 'pointer' }}
                />
            );
        } else {
            bullets.push(
                <img
                    key={i}
                    className="bullet-image transparent"
                    src={`${frontendUrl}/bullet-0.png`}
                    alt="Empty Bullet"
                    style={{ cursor: 'pointer' }}
                />
            );
        }
    }


    const WebApp = useWebApp();

    const handleClose = () => {
        WebApp.close();
    };

    const hours = user.miningTime > 0 ? Math.floor(user.miningTime / 3600) : 0;
    const minutes = user.miningTime > 0 ? Math.floor((user.miningTime % 3600) / 60) : 0;
    const seconds = user.miningTime > 0 ? Math.floor(user.miningTime % 60) : 0;

    const tokens = user.tokens;

    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {
        setShowOverlay(user.miningTime <= 0);
    }, [user.miningTime]);

    const [referralLink, setReferralLink] = useState(null);

    useEffect(() => {
        const fetchLink = async () => {
            try {
                const link = await fetchReferralLink(telegramId);
                setReferralLink(link);
            } catch (error) {
                console.log('Failed to fetch or generate referral link');
            }
        };
        fetchLink();
    }, [telegramId]);

    const shareOnTelegram = () => {
        if (referralLink) {
            const message = `Join ETHERNITY and earn extra xETNT! Here's my referral link! 🚀🚀🚀`;
            const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(message)}`;
            window.location.href = telegramShareUrl;
            window.open(telegramShareUrl, '_blank');
        }
    };

    const isConnected = user.walletAddress && user.walletAddress.length > 0;

    return (
        <div className="App" style={{ backgroundImage: `url(${equippedTokenURIWithPinata})` }}>
            {isLoading ? (
                <div className="bg-blur no-blur">
                    <div className="lds-ripple"><div></div><div></div></div>
                </div>
            ) : (
                <div className={`BackgroundContainer BackgroundImage${backgroundIndex}`}>
                    <div className="header">
                        <div className="header-icon header-icon-button" onClick={() => navigate('help')}>
                            <img
                                src={`${frontendUrl}/help.png`}
                                alt="Help"
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <div className="header-container">
                                <div className='wallet-container' onClick={() => window.open(`https://airdrop.ethernity.game/?telegramId=${telegramId}`, '_blank')}>
                                    <p className="wallet-name">Wallet</p>
                                    <div className='wallet-label'>
                                        <p>{isConnected ? 'Connected' : 'Not Connected'}</p>
                                        <div className={`wallet-connected ${isConnected ? 'true' : 'false'}`}></div>
                                    </div>
                                </div>
                            <div className="coin-and-title">

                                <img className="coin" src={`${frontendUrl}/coin.png`} alt="Coin" />
                                <h1 className="home-tokens">{tokens.toFixed(2)}</h1>
                                {user.miningTime > 0 && (
                                    <FloatingPoint
                                        pointsValue={user.miningMultiplier.toFixed(2)}
                                        triggerAnimation={animationTrigger}
                                    />
                                )}
                            </div>
                            <p className='tokens-label'><strong className="orange">xETNT</strong> earned</p>
                        </div>
                        <div className="header-icon header-icon-button" onClick={() => window.open('https://t.me/ethernitymain', '_blank')}>
                            <img
                                src={`${frontendUrl}/chat.png`}
                                alt="Community"
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    <div className="footer">
                        <p className="footer-label">Mining speed: ❤️ <strong className="orange">x{user.miningMultiplier.toFixed(4)}</strong></p>
                        <p className="footer-label">Total invited: 🙋🏻‍♂️ <strong className="orange">{user.firstLevelInvitedCount + user.secondLevelInvitedCount + user.thirdLevelInvitedCount}</strong></p>
                        <p className="footer-label">Time remaining: ⏱️ <strong className="orange">{hours}h {minutes}m {seconds}s</strong></p>
                        <div className="bullet-button-container">
                                <div className="bullet-button" onClick={handleBulletClick}>
                            {showOverlay && (
                                <div className="bullet-button-overlay glow-text">
                                    <p>Tap to reload</p>
                                </div>
                            )}
                            {bullets}
                        </div>
                        </div>
                        <FooterBar activeTab="home" referralLink={referralLink} />
                        <PopupNotification popupId="popup-id-nft-3" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;

