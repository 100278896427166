import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FooterBar from './FooterBar';
import axios from 'axios';
import { ReactComponent as LogoMark } from '../icons/Logomark-Blue.svg';
import { BackButton } from '@vkruglikov/react-telegram-web-app';
import NFTPrice from './NFTPrice';

import { ReactComponent as BackIcon } from '../icons/chevron-left-solid.svg';

// Helper function to determine the rarity class and label
const getRarityDetails = (rarity) => {
    switch (rarity) {
        case 100: return { label: 'Uncommon', className: 'uncommon', color: '🟢' };
        case 200: return { label: 'Rare', className: 'rare', color: '🔵' };
        case 300: return { label: 'Epic', className: 'epic', color: '🟣' };
        case 400: return { label: 'Legendary', className: 'legendary', color: '🟠' };
        case 500: return { label: 'Mythical', className: 'mythical', color: '🔴' };
        default: return { label: 'Common', className: 'unknown', color: '⚪' };
    }
};

const AllNFT = () => {
    const location = useLocation();
    const referralLink = location.state?.referralLink;
    const [nfts, setNfts] = useState([]);
    const [displayedNfts, setDisplayedNfts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const observer = useRef();
    const lastNftRef = useRef();
    const fetchDataCalled = useRef(false);

    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
    const telegramId = telegramUser ? telegramUser.id : '879289341';

    const navigate = useNavigate();

    const contractAddress = '0xb13624e8cc4fb4cd860c6d6c6f767776ea497946';
    const collectionSlug = 'ava-ethernity-genesis-ainft'; // Example collection slug

    const updatePinataUrl = (url) => {
        const pinataGatewayToken = "_F-lMKYPVKRdRfyrP7Lyv_mUDjQGEm-bFc_BLdb3VtFdifoZp7Mruw5je8WgLMoR";
        const pinataGatewayPrefix = "https://gateway.pinata.cloud/ipfs/";
        const newPrefix = "https://myethernity.mypinata.cloud/ipfs/";

        if (url.startsWith(pinataGatewayPrefix)) {
            const cid = url.split(pinataGatewayPrefix)[1];
            return `${newPrefix}${cid}?pinataGatewayToken=${pinataGatewayToken}`;
        }

        return url;
    };

    const fetchNftData = async (setNfts, setError) => {
        try {
            console.log('Fetching all NFT data...');

            // Fetch all NFTs
            const response = await axios.get(`/api/get-all-nfts`);
            console.log('Fetching all NFTs from URL:', `/api/get-all-nfts`);
            const nftsData = response.data.allNFTs;

            // Destructure the response to get token IDs and URIs
            const initialNfts = nftsData.allTokenIds.map((tokenId, index) => ({
                tokenId: tokenId,
                tokenURI: nftsData.allTokenURIs[index]
            }));
            setNfts(initialNfts);

        } catch (error) {
            setError(error.message);
            console.error('Error fetching all NFT data:', error); // Log the error to the console
        }
    };



    const fetchNftDetails = async (nft) => {
        try {
            const formattedTokenURI = updatePinataUrl(nft.tokenURI);
            console.log(`Fetching token URI for token ID ${nft.tokenId}:`, formattedTokenURI);
            const tokenURIResponse = await axios.get(formattedTokenURI);
            const nftData = { tokenId: nft.tokenId, ...tokenURIResponse.data };

            // Update the image URL if it matches the specified format
            nftData.image = updatePinataUrl(nftData.image);
            console.log(`Fetched data for token ID ${nft.tokenId}:`, nftData); // Log the token ID and data
            return nftData;
        } catch (nftError) {
            console.error(`Error fetching data for token ID ${nft.tokenId}:`, nftError);
            return null; // Return null if there's an error fetching this NFT
        }
    };

    const loadMoreNfts = useCallback(async () => {
        if (loading) return;
        setLoading(true);

        const nextNfts = nfts.slice(displayedNfts.length, displayedNfts.length + 4);

        // Check if there are no more NFTs to load
        if (nextNfts.length === 0) {
            console.log('No more NFTs to load.');
            setLoading(false);
            return;
        }

        const detailedNfts = await Promise.all(nextNfts.map(fetchNftDetails));
        const validNfts = detailedNfts.filter(nft => nft !== null);

        setDisplayedNfts(prevNfts => [...prevNfts, ...validNfts]);
        setLoading(false);

        console.log('Loaded more NFTs:', validNfts);
    }, [nfts, displayedNfts, loading]);


    useEffect(() => {
        const fetchData = async () => {
            if (fetchDataCalled.current) return;
            fetchDataCalled.current = true;

            await fetchNftData(setNfts, setError);
        };

        fetchData();
    }, []);


    useEffect(() => {
        if (nfts.length > 0) {
            loadMoreNfts();
        }
    }, [nfts]);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                loadMoreNfts();
            }
        });

        if (lastNftRef.current) {
            observer.current.observe(lastNftRef.current);
        }
    }, [loadMoreNfts, lastNftRef.current]);

    const handleCardClick = (e, nft) => {
        if (e.target.classList.contains('nft-button-use') ||
            e.target.classList.contains('sell-opensea') ||
            e.target.classList.contains('burn-nft') ||
            e.target.closest('.nft-button-use') ||
            e.target.closest('.sell-opensea') ||
            e.target.closest('.burn-nft')) {
            return; // Avoid flipping if clicking on functional buttons
        }

        const flippedCards = document.querySelectorAll('.flip-card.flipped');
        if (flippedCards.length > 0 && !e.currentTarget.classList.contains('flipped')) {
            flippedCards.forEach(card => card.classList.remove('flipped'));
        }

        e.currentTarget.classList.toggle('flipped');
    };

    return (
        <div className="App BackgroundImage0">
            {/* Conditional rendering based on displayedNfts length */}
            {!displayedNfts.length ? (
                <div className="bg-blur no-blur">
                    <div className="nft-loading">
                        <div className="lds-ripple"><div></div><div></div></div>
                    </div>
                </div>
            ) : (
                <div className="bg-blur">
                    <div className="nft-page list">
                        <div className="header">
                            <div className="header-icon header-icon-button" onClick={() => navigate('/nft')}>
                                <BackIcon className='header-icon-svg'></BackIcon>
                            </div>
                            <h1>All NFT</h1>
                            <div className="header-icon">
                            </div>
                        </div>
                            <div className="nft-container">
                                {displayedNfts.map((nft, index) => {
                                    const { label, className, color } = getRarityDetails(nft.attributes.find(attr => attr.trait_type === 'rarity').value);
                                    const ref = (index === displayedNfts.length - 1) ? lastNftRef : null;
                                    const openSeaLink = `https://opensea.io/assets/ethereum/${contractAddress}/${nft.tokenId}`;
                                    return (
                                        <div key={nft.tokenId} className={`nft-card flip-card ${className}`} ref={ref} onClick={(e) => handleCardClick(e, nft)}>
                                            <div className={`flip-card-inner ${className}`}>
                                                <div className="flip-card-front" style={{ backgroundImage: `url(${nft.image})` }}>
                                                    <div className='nft-card-header'>
                                                        <div className="sell-opensea"></div>
                                                        <p>{nft.name}</p>
                                                        <div className="sell-opensea" onClick={() => window.open(openSeaLink, '_blank')}>
                                                            <LogoMark className='logo-opensea' />
                                                        </div>
                                                    </div>
                                                    <div className='nft-card-body'>
                                                        <div className="nft-info">
                                                            <p>❤️+{nft.attributes.find(attr => attr.trait_type === 'multiplier').value}</p>
                                                            <p>{color}{label}</p>
                                                            <p>🪙{nft.attributes.find(attr => attr.trait_type === 'bonus').value}</p>
                                                        </div>
                                                        <NFTPrice collectionSlug={collectionSlug} tokenId={nft.tokenId} openSeaLink={openSeaLink} />
                                                    </div>
                                                </div>
                                                <div className="flip-card-back" style={{ backgroundImage: `url(${nft.image})` }}>
                                                    <div className='nft-attributes-wrapper'>
                                                        <div className='nft-attributes'>
                                                            <p>{nft.attributes.find(attr => attr.trait_type === 'name').value}</p>
                                                            <p><strong className='orange'>Age:</strong> {nft.attributes.find(attr => attr.trait_type === 'age').value}</p>
                                                            <p><strong className='orange'>Race:</strong> {nft.attributes.find(attr => attr.trait_type === 'race').value}</p>
                                                            <p><strong className='orange'>Profession:</strong> {nft.attributes.find(attr => attr.trait_type === 'profession').value}</p>
                                                            <p><strong className='orange'>Bio:</strong> {nft.attributes.find(attr => attr.trait_type === 'bio').value}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="nft-card flip-card mythical no-overflow">
                                    <div className="nft-card-overlay">
                                        <p className='glow-text'>Coming Soon</p>
                                    </div>
                                    <div className="flip-card-inner mythical">
                                        <div className="flip-card-front" style={{ backgroundImage: 'url("https://bot.ethernity.game/nft-demo-new.jpg")' }}>
                                            <div className='nft-card-header'>
                                                <p>AVA #4</p>
                                                <div className="sell-opensea">
                                                    <LogoMark className='logo-opensea' />
                                                </div>
                                            </div>
                                            <div className='nft-card-body'>
                                                <div className="nft-info">
                                                    <p>❤️+5</p>
                                                    <p>🔴Mythical</p>
                                                    <p>🪙500,000</p>
                                                </div>
                                                <div className="nft-button-use">Equip</div>
                                            </div>
                                        </div>
                                        <div className="flip-card-back">
                                            <h1>John Doe</h1>
                                            <p>Architect & Engineer</p>
                                            <p>We love that guy</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
            )}

            <FooterBar activeTab='nft' fixed={true} referralLink={referralLink} />
            <BackButton onClick={() => navigate('/nft')} />
        </div>
    );
};

export default AllNFT;
