//Quests.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BackButton } from '@vkruglikov/react-telegram-web-app';
import { fetchReferralLink } from '../utils/referralLink';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { sendGreeting } from '../utils/sendGreeting';

import FooterBar from './FooterBar';


const Quests = () => {
    const [initData, setInitData] = useState(window.Telegram.WebApp.initData || '');

    useEffect(() => {
        if (window.Telegram.WebApp.initData) {
            setInitData(window.Telegram.WebApp.initData);
        }
    }, []);
    const frontendUrl = "https://telegram-web-app-frontend-deploy.vercel.app";
    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
    const telegramId = telegramUser ? telegramUser.id : 'unknown';
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [referralLink, setReferralLink] = useState(location.state?.referralLink || null);

    useEffect(() => {
        if (!referralLink) {
            fetchReferralLink(telegramId)
                .then((link) => setReferralLink(link))
                .catch(() => console.log('Failed to fetch or generate referral link'));
        }
    }, [referralLink, telegramId]);

    const shareOnTelegram = () => {
        if (referralLink) {
            const message = `Join ETHERNITY and earn extra xETNT! Here's my referral link! 🚀🚀🚀`;
            const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(message)}`;
            window.location.href = telegramShareUrl;
            window.open(telegramShareUrl, '_blank');
        } else {
            console.log('Referral link is not available');
        }
    };

    const WebApp = useWebApp();

    const handleClose = () => {
        WebApp.close(); // This will close the web app
    };

    const [quests, setQuests] = useState([
        { id: "id0", action: () => window.open(`https://airdrop.ethernity.game/?telegramId=${telegramId}`, '_blank'), description: "Connect wallet to claim airdop", reward: "+10000 xETNT" },
        { id: "id1", action: () => window.open('https://t.me/ethernitygame', '_blank'), description: "Subscribe to our Telegram channel", reward: "+2000 xETNT" },
        { id: "id2", action: shareOnTelegram, description: "Invite 10 friends", reward: "+20000 xETNT" },
        { id: "id3", action: () => window.open('https://twitter.com/0xETHERNITY', '_blank'), description: "Follow us on X", reward: "+1500 xETNT" },
        { id: "id4", action: () => window.open('https://discord.com/invite/5ze32SFmmS', '_blank'), description: "Join our Discord server", reward: "+1500 xETNT" },
        { id: "id5", action: () => window.open('https://forms.gle/TwSSCZ5QMu8Ybkzz6', '_blank'), description: "Complete our survey", reward: "+2000 xETNT" },
        { id: "id6", action: () => window.open('https://www.instagram.com/ethernityava', '_blank'), description: "Subscribe to our Instagram", reward: "+1500 xETNT" },
        // { id: "id9", action: () => window.open(`https://airdrop.ethernity.game/mint/?telegramId=${telegramId}`, '_blank'), description: "Visit the Ava Genesis AINFT mint page", reward: "+5000 xETNT" },
        // { id: "id10", action: () => window.open('https://x.com/0xETHERNITY/status/1797629512282492971', '_blank'), description: "Like and repost post on our X", reward: "+2000 xETNT" },
        { id: "id11", action: () => window.open('https://www.youtube.com/watch?v=6Ge7-2ea0Ds', '_blank'), description: "Watch video, like and subscribe", reward: "+10000 xETNT" },
        { id: "id12", action: () => window.open('https://t.me/nightroncrypto', '_blank'), description: "Join Telegram Channel of Knightron", reward: "+10000 xETNT" },
        { id: "id13", action: () => window.open('https://x.com/0xETHERNITY/status/1800245600732279033?t=OacpFdrXda3pr3yiOMMWng&s=19', '_blank'), description: "Like and repost post on our X", reward: "+2000 xETNT" },
        { id: "id14", action: () => window.open('https://store.epicgames.com/en-US/p/ailand-be3b49', '_blank'), description: "Add ETHERNITY to wishlist on EPIC Games", reward: "+5000 xETNT" },
        { id: "id15", action: () => window.open('https://youtu.be/QkMLtXndIiE?si=vT-yQrvIImSbhKTe', '_blank'), description: "Watch game trailer, like and subscribe", reward: "+5000 xETNT" },
        { id: "id16", action: () => window.open('https://youtu.be/OZkroYSFI9Q', '_blank'), description: "Watch video, like and subscribe", reward: "+5000 xETNT" },




        { id: "daily_id1", action: () => window.open('https://t.me/ethernitymain', '_blank'), description: "Write a message in our group", reward: "+500 xETNT" },
        {
            id: "daily_id2", action: () => {
                sendGreeting(telegramId)
                    .then(() => {
                        handleClose();
                    })
                    .catch((error) => {
                        console.error('Failed to send greeting:', error);
                    });
            }, description: "Chat with Ava", reward: "+500 xETNT"
        },
        { id: "daily_id3", action: shareOnTelegram, description: "Invite 3 friends", reward: "+2500 xETNT" },
        { id: "daily_id4", action: () => window.open('https://www.instagram.com/ethernityava', '_blank'), description: "Like 3 posts on our Instagram", reward: "+1000 xETNT" },
        { id: "daily_id5", action: () => window.open('https://opensea.io/collection/ava-ethernity-genesis-ainft', '_blank'), description: "Visit the collection on OpenSea", reward: "+1000 xETNT" },

    ]);
    const [completedQuests, setCompletedQuests] = useState([]);

    useEffect(() => {
        fetchCompletedQuests();
    }, []);

    const fetchCompletedQuests = async () => {
        setIsLoading(true); // Start loading
        try {
            const response = await axios.get(`/api/fetch-completed-quests?telegramId=${telegramId}`)
;
            setCompletedQuests(response.data.completedQuests);
            setIsLoading(false); // End loading after data is fetched
        } catch (error) {
            setIsLoading(false); // End loading even on error
            console.error('Failed to fetch completed quests:', error);
        }
    };

    const completeQuest = async (questId) => {
        try {
            axios.post('/api/mark-quest-completed', { initData, questId })
            fetchCompletedQuests();  // Refresh the completed quests list
        } catch (error) {
            console.error('Failed to complete quest:', error);
        }
    };


    // Update the quest elements mapping to use completeAndPerformQuest for specific ids
    const questElements = quests.filter(quest => !completedQuests.includes(quest.id)).map(quest => (
        <div key={quest.id} className={`quest ${quest.id.includes('daily') ? '' : 'one-time'}`} onClick={() => {
            if (quest.id === 'id2' || quest.id === 'daily_id3' || quest.id === 'id0') {
                completeAndPerformQuest(quest);
            } else {
                quest.action();
                completeQuest(quest.id);
            }
        }}>
            <div className="quest-icon">
                <img src={`${frontendUrl}/quests/quest-${quest.id}.jpg`} alt="Quest icon" />
            </div>
            <div className="quest-content">
                <p>{quest.description}</p>
                <p className="glow-text">{quest.reward}</p>
            </div>
        </div>
    ));

    const completeAndPerformQuest = async (quest) => {
        try {
            await completeQuest(quest.id);
            quest.action(); // Call the action after the quest is completed
        } catch (error) {
            console.error('Failed to complete quest:', error);
        }
    };

    const questsNotification = (
        <div className="quests-notification">
            <div>
                <p style={{ fontSize: '64px', lineHeight: '64px', marginBottom: "16px" }}>🚀</p>
                <h1>COMING SOON</h1>
                <p>There are no available<br /> quests right now. <br />Please come back tomorrow</p>
            </div>
        </div>
    );

    return (
        <div className="App BackgroundImage3">
            {isLoading ? (
                <div className="bg-blur no-blur">
                <div className="lds-ripple"><div></div><div></div></div>
                </div>  // Display loading message or spinner here
            ) : (
                <div className="bg-blur">
                    {questElements.length > 0 && (
                        <div className="quests-page">
                            <h1>Quests</h1>
                            <div className="quests-container">
                                {questElements}
                            </div>
                        </div>
                    )}
                    {questElements.length === 0 && questsNotification}

                </div>
            )}
            <FooterBar activeTab="quests" fixed={true} referralLink={referralLink} />
            <BackButton onClick={() => navigate('/')} />
        </div>
    );
};

export default Quests;
