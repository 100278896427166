//Invite.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BackButton } from '@vkruglikov/react-telegram-web-app';
import { fetchReferralLink } from '../utils/referralLink';
import FooterBar from './FooterBar';

const Invite = () => {
    const [initData, setInitData] = useState(window.Telegram.WebApp.initData || '');

    useEffect(() => {
        if (window.Telegram.WebApp.initData) {
            setInitData(window.Telegram.WebApp.initData);
        }
    }, []);


    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
    const telegramId = telegramUser ? telegramUser.id : 'unknown';
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState({
        firstLevelInvitedCount: 0,
        secondLevelInvitedCount: 0,
        thirdLevelInvitedCount: 0,
        firstLevelTokens: 0,
        secondLevelTokens: 0,
        thirdLevelTokens: 0,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [referralLink, setReferralLink] = useState(location.state?.referralLink || null);


    const requestInProgress = useRef(false);

    useEffect(() => {
        if (!requestInProgress.current) {
            setIsLoading(true); // Start loading
            requestInProgress.current = true;
            console.log('Fetching user info');
            axios.post('/api/user-info', { initData })
                .then((response) => {
                    setUser(response.data);
                    setIsLoading(false); // End loading after data is fetched
                    requestInProgress.current = false;
                })
                .catch((error) => {
                    console.error('Failed to fetch user info:', error);
                    setIsLoading(false); // End loading even on error
                    requestInProgress.current = false;
                    console.log('Failed to fetch user info');
                });
        }
    }, [telegramId]);



    useEffect(() => {
        if (!referralLink) {
            fetchReferralLink(telegramId)
                .then((link) => setReferralLink(link))
                .catch(() => console.log('Failed to fetch or generate referral link'));
        }
    }, [referralLink, telegramId]);

    const shareOnTelegram = () => {
        if (referralLink) {
            const message = `Join ETHERNITY and earn extra xETNT! Here's my referral link! 🚀🚀🚀`;
            const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(message)}`;
            window.location.href = telegramShareUrl;
            window.open(telegramShareUrl, '_blank');
        } else {
            console.log('Referral link is not available');
        }
    };

    return (
        <div className="App BackgroundImage3">
            {isLoading ? (
                <div className="bg-blur no-blur">
                    <div className="lds-ripple"><div></div><div></div></div>
                </div>  // Display loading message or spinner here
            ) : (
            <div className="bg-blur">
                <div className="invite-logo-placeholder">
                    <img
                        className="invite-logo"
                        src="https://bot.ethernity.game/logo.png"
                        alt="Logo"
                    />
                </div>
                <p>Invite Friends to get extra xETNT<br /> until 3rd line of friends</p>
                <div className="invite-stats-container no-bg">
                    <div className="invite-stats-line">
                        <p>Line 1</p>
                        <p></p>
                        <p>+2000</p>
                        <p>xETNT</p>
                    </div>
                    <div className="invite-stats-line">
                        <p>Line 2</p>
                        <p></p>
                        <p>+1500</p>
                        <p>xETNT</p>
                    </div>
                    <div className="invite-stats-line">
                        <p>Line 3</p>
                        <p></p>
                        <p>+1000</p>
                        <p>xETNT</p>
                    </div>
                </div>
                <div className="invite-stats-container">
                    <div className="invite-stats">
                        <div>
                            <h2>{user.firstLevelInvitedCount + user.secondLevelInvitedCount + user.thirdLevelInvitedCount}</h2>
                            <h3>Invited</h3>
                        </div>
                        <div>
                            <h2>{(user.firstLevelTokens + user.secondLevelTokens + user.thirdLevelTokens).toLocaleString()}</h2>
                            <h3>Earned</h3>
                        </div>
                    </div>
                    <div className="invite-stats-line">
                        <p>Line 1</p>
                        <p>{user.firstLevelInvitedCount}</p>
                        <p>{user.firstLevelTokens.toLocaleString()}</p>
                        <p>xETNT</p>
                    </div>
                    <div className="invite-stats-line">
                        <p>Line 2</p>
                        <p>{user.secondLevelInvitedCount}</p>
                        <p>{user.secondLevelTokens.toLocaleString()}</p>
                        <p>xETNT</p>
                    </div>
                    <div className="invite-stats-line">
                        <p>Line 3</p>
                        <p>{user.thirdLevelInvitedCount}</p>
                        <p>{user.thirdLevelTokens.toLocaleString()}</p>
                        <p>xETNT</p>
                    </div>
                </div>
                <button className="invite-button" onClick={shareOnTelegram}>Invite</button>
                <BackButton onClick={() => navigate('/')} />
            </div>
            )}
            <FooterBar activeTab='invite' fixed={true} referralLink={referralLink} />
        </div>
    );
};

export default Invite;
