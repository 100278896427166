import React, { useEffect, useState, useRef, useCallback } from 'react'; // updated import
import { useLocation, useNavigate } from 'react-router-dom';
import FooterBar from './FooterBar';
import axios from 'axios';
import { ReactComponent as LogoMark } from '../icons/Logomark-Blue.svg';
import { ReactComponent as ToAllIcon } from '../icons/images-solid.svg';
import { ReactComponent as HelpIcon } from '../icons/circle-question-solid.svg';

// Helper function to determine the rarity class and label
const getRarityDetails = (rarity) => {
    switch (rarity) {
        case 100: return { label: 'Uncommon', className: 'uncommon', color: '🟢' };
        case 200: return { label: 'Rare', className: 'rare', color: '🔵' };
        case 300: return { label: 'Epic', className: 'epic', color: '🟣' };
        case 400: return { label: 'Legendary', className: 'legendary', color: '🟠' };
        case 500: return { label: 'Mythical', className: 'mythical', color: '🔴' };
        default: return { label: 'Common', className: 'unknown', color: '⚪' };
    }
};

// Helper function to format IPFS URL
const formatIpfsUrl = (url) => {
    if (url.startsWith('ipfs://')) {
        const cid = url.split('ipfs://')[1];
        return `https://${cid}.ipfs.nftstorage.link/`;
    }
    return url;
};

const NFT = () => {
    const [initData, setInitData] = useState(window.Telegram.WebApp.initData || '');

    useEffect(() => {
        if (window.Telegram.WebApp.initData) {
            setInitData(window.Telegram.WebApp.initData);
        }
    }, []);

    const location = useLocation();
    const navigate = useNavigate();
    const referralLink = location.state?.referralLink;
    const [nfts, setNfts] = useState([]);
    const [equippedTokenId, setEquippedTokenId] = useState(null);
    const [error, setError] = useState(null);
    const fetchDataCalled = useRef(false);
    const [displayedNfts, setDisplayedNfts] = useState([]); // new state
    const [loading, setLoading] = useState(false); // new state
    const observer = useRef(); // new ref
    const lastNftRef = useRef(); // new ref

    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
    const telegramId = telegramUser ? telegramUser.id : 'unknown';

    const contractAddress = '0xb13624e8cc4fb4cd860c6d6c6f767776ea497946';

    const defaultNft = {
        tokenId: '0',
        name: 'DEFAULT SKIN',
        image: 'https://bot.ethernity.game/new-bg.jpg',
        attributes: [
            { trait_type: 'rarity', value: 0 },
            { trait_type: 'multiplier', value: 0 },
            { trait_type: 'bonus', value: 0 },
            { trait_type: 'name', value: 'Ava' },
            { trait_type: 'age', value: '19' },
            { trait_type: 'race', value: 'Human' },
            { trait_type: 'profession', value: 'Assistant' },
            { trait_type: 'bio', value: 'Ava is your best friend and guide in the world of Ethernity. She is always there to help you and provide valuable advice.' }
        ]
    };

    useEffect(() => {
        const ws = new WebSocket('wss://telegram-web-app-backend.azurewebsites.net');

        ws.onopen = () => {
            console.log('WebSocket connection established');
        };

        ws.onmessage = async (event) => {
            if (event.data instanceof Blob) {
                const text = await event.data.text();
                try {
                    const message = JSON.parse(text);
                    console.log('Parsed WebSocket message:', message);
                    if (message.type === 'UPDATE_NFT_LIST') {
                        refetchData(); // Call refetchData when an update message is received
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = () => {
            console.log('WebSocket connection closed');
        };

        return () => {
            ws.close();
        };
    }, []);

    const fetchNftData = async (telegramId, setEquippedTokenId, setNfts, setError, defaultNft) => {
        const updatePinataUrl = (url) => {
            const pinataGatewayToken = "_F-lMKYPVKRdRfyrP7Lyv_mUDjQGEm-bFc_BLdb3VtFdifoZp7Mruw5je8WgLMoR";
            const pinataGatewayPrefix = "https://gateway.pinata.cloud/ipfs/";
            const newPrefix = "https://myethernity.mypinata.cloud/ipfs/";

            if (url.startsWith(pinataGatewayPrefix)) {
                const cid = url.split(pinataGatewayPrefix)[1];
                return `${newPrefix}${cid}?pinataGatewayToken=${pinataGatewayToken}`;
            }

            return url;
        };

        try {
            console.log('Fetching NFT data...');
            const userResponse = await axios.post('/api/user-info', { initData });
            const userData = userResponse.data;
            let equippedNFT = userData.equippedNFT || { tokenId: defaultNft.tokenId, tokenURI: defaultNft.image, multiplier: 0 };

            console.log("Equipped NFT: ", equippedNFT);

            const response = await axios.get(`/api/get-owned-nfts?telegramId=${telegramId}`);
            console.log('Fetching owned NFTs from URL:', `/api/get-owned-nfts?telegramId=${telegramId}`);
            const nftsData = response.data.ownedNFTs;

            const fetchedNfts = await Promise.all(nftsData.map(async (nft) => {
                try {
                    const formattedTokenURI = updatePinataUrl(nft.tokenURI);
                    console.log(`Fetching token URI for token ID ${nft.tokenId}:`, formattedTokenURI);
                    const tokenURIResponse = await axios.get(formattedTokenURI);
                    const nftData = { tokenId: nft.tokenId, ...tokenURIResponse.data };

                    nftData.image = updatePinataUrl(nftData.image);
                    console.log(`Fetched data for token ID ${nft.tokenId}:`, nftData);
                    return nftData;
                } catch (nftError) {
                    console.error(`Error fetching data for token ID ${nft.tokenId}:`, nftError);
                    return null;
                }
            }));

            const validNfts = fetchedNfts.filter(nft => nft !== null);
            setNfts([defaultNft, ...validNfts]);
            setDisplayedNfts([defaultNft, ...validNfts.slice(0, 4)]);

            if (equippedNFT && equippedNFT.tokenId !== defaultNft.tokenId) {
                const nftExists = validNfts.some(nft => nft.tokenId === equippedNFT.tokenId);

                if (!nftExists) {
                    console.log('Equipped NFT not found in owned NFTs:', equippedNFT);
                    equippedNFT = {
                        tokenId: defaultNft.tokenId,
                        tokenURI: defaultNft.image,
                        multiplier: 0,
                        name: 'Ava',
                        age: '19',
                        race: 'Human',
                        profession: 'Assistant',
                        bio: 'Ava is your best friend and guide in the world of Ethernity. She is always there to help you and provide valuable advice.'
                    };

                    await axios.post('/api/equip-nft', {
                        telegramId,
                        tokenId: equippedNFT.tokenId,
                        tokenURI: equippedNFT.tokenURI,
                        multiplier: equippedNFT.multiplier,
                        name: equippedNFT.name,
                        age: equippedNFT.age,
                        race: equippedNFT.race,
                        profession: equippedNFT.profession,
                        bio: equippedNFT.bio
                    });

                    setEquippedTokenId(equippedNFT.tokenId);

                    console.log('Equipped NFT updated to default:', equippedNFT);
                } else {
                    setEquippedTokenId(equippedNFT.tokenId);
                    console.log('Set equipped NFT:', equippedNFT);
                }
            } else {
                setEquippedTokenId(equippedNFT ? equippedNFT.tokenId : '0');
                console.log('Set equipped NFT:', equippedNFT);
            }

            console.log('Fetched NFT data:', validNfts);
        } catch (error) {
            setError(error.message);
            setNfts([defaultNft]);
            console.error('Error fetching NFT data:', error);
            setEquippedTokenId('0');
        }
    };

    const loadMoreNfts = useCallback(async () => {
        if (loading) return;
        setLoading(true);

        const nextNfts = nfts.slice(displayedNfts.length, displayedNfts.length + 4);

        if (nextNfts.length === 0) {
            console.log('No more NFTs to load.');
            setLoading(false);
            return;
        }

        setDisplayedNfts(prevNfts => [...prevNfts, ...nextNfts]);
        setLoading(false);

        console.log('Loaded more NFTs:', nextNfts);
    }, [nfts, displayedNfts, loading]);

    useEffect(() => {
        if (nfts.length > 0) {
            loadMoreNfts();
        }
    }, [nfts]);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                loadMoreNfts();
            }
        });

        if (lastNftRef.current) {
            observer.current.observe(lastNftRef.current);
        }
    }, [loadMoreNfts]);

    const refetchData = async () => {
        console.log('Refetching NFT data...');
        await fetchNftData(telegramId, setEquippedTokenId, setNfts, setError, defaultNft);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (fetchDataCalled.current) return;
            fetchDataCalled.current = true;

            await fetchNftData(telegramId, setEquippedTokenId, setNfts, setError, defaultNft);
        };

        fetchData();
    }, [telegramId]);

    const handleEquip = async (nft) => {
        try {
            console.log('Equipping NFT:', nft);

            const attributes = nft.attributes.reduce((acc, attr) => {
                acc[attr.trait_type] = attr.value;
                return acc;
            }, {});

            if (nft.tokenId === defaultNft.tokenId) {
                attributes.name = 'Ava';
                attributes.age = '19';
                attributes.race = 'Human';
                attributes.profession = 'Assistant';
                attributes.bio = 'Ava is your best friend and guide in the world of Ethernity. She is always there to help you and provide valuable advice.';
            }

            const payload = {
                telegramId,
                tokenId: nft.tokenId,
                tokenURI: nft.image,
                ...attributes
            };

            const response = await axios.post('/api/equip-nft', payload);
            console.log('Equip response:', response.data);

            setEquippedTokenId(nft.tokenId);
        } catch (error) {
            console.error('Error equipping NFT:', error.message);
        }
    };

    const targetDate = "2024-06-03T12:00:00Z";

    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                d: Math.floor(difference / (1000 * 60 * 60 * 24)),
                h: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                m: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                s: Math.floor((difference % (1000 * 60)) / 1000)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval}>
                {timeLeft[interval]}{interval}{" "}
            </span>
        );
    });

    const handleCardClick = (e, nft) => {
        if (e.target.classList.contains('nft-button-use') ||
            e.target.classList.contains('sell-opensea') ||
            e.target.classList.contains('burn-nft') ||
            e.target.closest('.nft-button-use') ||
            e.target.closest('.sell-opensea') ||
            e.target.closest('.burn-nft')) {
            return; // Avoid flipping if clicking on functional buttons
        }

        const flippedCards = document.querySelectorAll('.flip-card.flipped');
        if (flippedCards.length > 0 && !e.currentTarget.classList.contains('flipped')) {
            flippedCards.forEach(card => card.classList.remove('flipped'));
        }

        e.currentTarget.classList.toggle('flipped');
    };

    return (
        <div className="App BackgroundImage0">
            {!nfts.length ? (
                <div className="bg-blur no-blur">
                    <div className="nft-loading">
                        <div className="lds-ripple"><div></div><div></div></div>
                    </div>
                </div>
            ) : (
                <div className="bg-blur">
                    <div className="nft-page">
                        <div className="header">
                            <div className="header-icon header-icon-button" onClick={() => navigate('/nfthelp')}>
                                <HelpIcon className='header-icon-svg'></HelpIcon>
                            </div>
                            <h1>Your NFT</h1>
                            <div className="header-icon header-icon-button all-nft" onClick={() => {navigate('/allnft')}}>
                                <ToAllIcon className='header-icon-svg'></ToAllIcon>
                            </div>
                        </div>

                        <div className="nft-container">
                            {displayedNfts.map((nft, index) => {
                                const { label, className, color } = getRarityDetails(nft.attributes.find(attr => attr.trait_type === 'rarity').value);
                                const isEquipped = nft.tokenId === equippedTokenId;
                                const ref = (index === displayedNfts.length - 1) ? lastNftRef : null;
                                return (
                                    <div key={nft.tokenId} className={`nft-card flip-card ${className}`} ref={ref} onClick={(e) => handleCardClick(e, nft)}>
                                        <div className={`flip-card-inner ${className}`}>
                                            <div className="flip-card-front" style={{ backgroundImage: `url(${nft.image})` }}>
                                                <div className='nft-card-header'>
                                                    {nft.tokenId !== '0' && (
                                                        <div className="burn-nft" onClick={() => window.open(`https://airdrop.ethernity.game/burn/?telegramId=${telegramId}&tokenId=${nft.tokenId}`, '_blank')}>
                                                            🔥
                                                        </div>
                                                    )}
                                                    <p>{nft.name}</p>
                                                    {nft.tokenId !== '0' && (
                                                        <div className="sell-opensea" onClick={() => window.open(`https://opensea.io/assets/ethereum/${contractAddress}/${nft.tokenId}/`, '_blank')}>
                                                            <LogoMark className='logo-opensea' />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='nft-card-body'>
                                                    <div className="nft-info">
                                                        <p>❤️+{nft.attributes.find(attr => attr.trait_type === 'multiplier').value}</p>
                                                        <p>{color}{label}</p>
                                                        <p>🪙{nft.attributes.find(attr => attr.trait_type === 'bonus').value}</p>
                                                    </div>
                                                    <div className={`nft-button-use ${isEquipped ? 'equipped' : ''}`} onClick={() => handleEquip(nft)}>
                                                        {isEquipped ? 'Selected' : 'Select'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flip-card-back" style={{ backgroundImage: `url(${nft.image})` }}>
                                                <div className='nft-attributes-wrapper'>
                                                    <div className='nft-attributes'>
                                                        <p>{nft.attributes.find(attr => attr.trait_type === 'name').value}</p>
                                                        <p><strong className='orange'>Age:</strong> {nft.attributes.find(attr => attr.trait_type === 'age').value}</p>
                                                        <p><strong className='orange'>Race:</strong> {nft.attributes.find(attr => attr.trait_type === 'race').value}</p>
                                                        <p><strong className='orange'>Profession:</strong> {nft.attributes.find(attr => attr.trait_type === 'profession').value}</p>
                                                        <p><strong className='orange'>Bio:</strong> {nft.attributes.find(attr => attr.trait_type === 'bio').value}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            <div className="nft-card flip-card mythical no-overflow">
                                <div className="nft-card-overlay">
                                        <p className='glow-text'>Sold out!</p>
                                        <button className='pulse-orange-button' onClick={() => window.open(`https://opensea.io/collection/ava-ethernity-genesis-ainft`, '_blank')}>OpenSea</button>
                                </div>
                                <div className="flip-card-inner mythical">
                                    <div className="flip-card-front" style={{ backgroundImage: 'url("https://bot.ethernity.game/nft-demo-new.jpg")' }}>
                                        <div className='nft-card-header'>
                                            <p>AVA #4</p>
                                            <div className="sell-opensea">
                                                <LogoMark className='logo-opensea' />
                                            </div>
                                        </div>
                                        <div className='nft-card-body'>
                                            <div className="nft-info">
                                                <p>❤️+5</p>
                                                <p>🔴Mythical</p>
                                                <p>🪙500,000</p>
                                            </div>
                                            <div className="nft-button-use">Equip</div>
                                        </div>
                                    </div>
                                    <div className="flip-card-back">
                                        <h1>John Doe</h1>
                                        <p>Architect & Engineer</p>
                                        <p>We love that guy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nft-help-container">
                                <h2>🚀 SOLD OUT! 🚀</h2>
                                <p className='glow-text'>Ava Genesis AINFT are sold out!</p>
                                <button className='pulse-orange-button' onClick={() => window.open(`https://opensea.io/collection/ava-ethernity-genesis-ainft`, '_blank')}>OpenSea</button>

                            <div className='nft-help-container-text'>
                                <p><strong className='orange'>👑 Your Unique 1 of 1 Ava</strong></p>
                                <br />
                                <p>Own a one-of-a-kind Ava NFT!</p>
                                <br />
                                <p><strong className='orange'>✨ Change Ava's Look</strong></p>
                                <br />
                                <p>Use NFT Skins to give Ava a fresh appearance!</p>
                                <br />
                                <p><strong className='orange'>❤️ Boost Mining</strong></p>
                                <br />
                                <p>Equip NFTs for a multiplier boost in mining!</p>
                                <br />
                                <p><strong className='orange'>🔄 Trade on OpenSea</strong></p>
                                <br />
                                <p>Buy, sell, and trade your NFTs on OpenSea!</p>
                                <br />
                                <p><strong className='orange'>🔥 Burn for xETNT</strong></p>
                                <br />
                                <p>Burn your NFT to get an instant xETNT bonus!</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <FooterBar activeTab='nft' fixed={true} referralLink={referralLink} />
        </div>
    );
};

export default NFT;
