//sendGreeting.js

import axios from 'axios';


export const sendGreeting = (telegramId) => {
    return axios
        .post('/api/send-greeting', { telegramId })
        .then(() => {
            console.log(`Greeting sent to user ${telegramId}`);
        })
        .catch((error) => {
            console.error(`Failed to send greeting to user ${telegramId}:`, error);
        });
};
