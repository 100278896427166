// referralLink.js
import axios from 'axios';

export const fetchReferralLink = async (telegramId) => {
    try {
        // Make the request and return the promise
        const response = await axios.get(`/api/fetch-or-generate-referral?telegramId=${telegramId}`);
        // Return the referral link directly
        return response.data.referralLink;
    } catch (error) {
        console.error('Failed to fetch or generate referral link:', error);
        throw error;  // Rethrow the error to be handled by the caller
    }
};
