import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactComponent as CloseIcon } from '../icons/circle-xmark-regular.svg';

const PopupNotification = ({ popupId }) => {
    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
    const telegramId = telegramUser ? telegramUser.id : 'unknown';
    const [isPopupViewed, setIsPopupViewed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const checkPopupViewed = async () => {
            try {
                const response = await axios.get('/api/check-popup-viewed', {
                    params: { telegramId, popupId }
                });
                setIsPopupViewed(response.data.viewed);
            } catch (error) {
                console.error('Failed to check if popup was viewed:', error);
            } finally {
                setIsLoading(false);
            }
        };

        checkPopupViewed();
    }, [telegramId, popupId]);

    const handleClosePopup = async () => {
        setIsClosing(true);
        setTimeout(async () => {
            try {
                await axios.post('/api/mark-popup-viewed', { telegramId: String(telegramId), popupId });
                setIsPopupViewed(true);
            } catch (error) {
                console.error('Failed to mark popup as viewed:', error);
                setIsPopupViewed(true);
            }
        }, 500); // Duration of the slide down animation
    };

    if (isLoading || isPopupViewed) return null;

    return (
        <div className={`popup ${isClosing ? 'closing' : ''}`}>
            <div className='popup-content'>
                <CloseIcon className='popup-close-icon' onClick={handleClosePopup} />
                <h2>🚀 NFT Mint is SOLD OUT! 🚀</h2>
                <p className='glow-text'>Thank you for your support!</p>
                <p>The Genesis AINFT collection is sold out!<br />Stay tuned for more exciting releases. </p>
                <p><b className='orange'>⚠️ ALL 150 WERE CLAIMED! ⚠️</b></p>
                <button onClick={() => window.open(`https://opensea.io/collection/ava-ethernity-genesis-ainft`, '_blank')}>OpenSea</button>
            </div>
        </div>
    );
};

export default PopupNotification;
