// FloatingPoints.js

import React, { useEffect, useState, useCallback } from 'react';

const FloatingPoint = ({ pointsValue, triggerAnimation }) => {
    const [points, setPoints] = useState([]);
    const [key, setKey] = useState(0);

    useEffect(() => {
        if (triggerAnimation) {
            const newKey = key + 1;
            setPoints(points => [...points, { id: newKey, value: pointsValue }]);
            setKey(newKey);
        }
    }, [triggerAnimation, pointsValue]); // React to changes in triggerAnimation

    const handleAnimationEnd = useCallback((pointId) => {
        setPoints(points => points.filter(point => point.id !== pointId));
    }, []);

    return (
        <div className="floating-point-container">
            {points.map(point => (
                <div key={point.id} className="floating-point" onAnimationEnd={() => handleAnimationEnd(point.id)}>
                    +{point.value}
                </div>
            ))}
        </div>
    );
};

export default FloatingPoint;
