// Help.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '@vkruglikov/react-telegram-web-app';

import { ReactComponent as BackIcon } from '../icons/chevron-left-solid.svg';



const Help = () => {
    const navigate = useNavigate();

    return (
        <div className="App BackgroundImage3">
            <div className="bg-blur">
                <div className="help-page">
                    <div className="header">
                        <div className="header-icon header-icon-button" onClick={() => navigate('/nft')}>
                            <BackIcon className='header-icon-svg'></BackIcon>
                        </div>
                        <h1>Help</h1>
                        {/* <div className="header-icon header-icon-button" onClick={() => navigate('/allnft')}>
                                    <ToAllIcon className='header-icon-svg'></ToAllIcon>
                                </div> */}
                        <div className="header-icon"></div>
                    </div>
                    <div className="help-container">
                        <div className="help-element">
                            <p className='orange'>⏳ Coming Soon</p>
                            <p>Stay updated to not miss out on the mint!</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>👑 Your Unique 1 of 1 Ava</p>
                            <p>Own a one-of-a-kind Ava NFT!</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>✨ Change Ava's Look</p>
                            <p>Use NFT Skins to give Ava a fresh appearance!</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>❤️ Boost Mining</p>
                            <p>Equip NFTs for a multiplier boost in mining!</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>🔄 Trade on OpenSea</p>
                            <p>Buy, sell, and trade your NFTs on OpenSea!</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>🔥 Burn for xETNT</p>
                            <p>Burn your NFT to get an instant xETNT bonus!</p>
                        </div>
                    </div>
                </div>
            </div>
            <BackButton onClick={() => navigate('/')} />
        </div>
    );
};

export default Help;