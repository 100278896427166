import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ListCheckIcon } from '../icons/list-check-solid.svg';
import { ReactComponent as UsersIcon } from '../icons/users-solid.svg';
import { ReactComponent as HeartIcon } from '../icons/heart-solid.svg';
import { ReactComponent as GemIcon } from '../icons/gem-solid.svg';
import { ReactComponent as HomeIcon } from '../icons/house-solid.svg';
import { sendGreeting } from '../utils/sendGreeting';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import axios from 'axios';

const FooterBar = ({ activeTab, fixed = false, referralLink }) => {
    const TOTAL_QUESTS_COUNT = 18;

    const navigate = useNavigate();
    const WebApp = useWebApp();
    const telegramUser = window.Telegram.WebApp.initDataUnsafe.user;
    const telegramId = telegramUser ? telegramUser.id : 'unknown';

    // const [quests, setQuests] = useState([
    //     { id: "id0", description: "Connect wallet to claim airdrop" },
    //     { id: "id1", description: "Subscribe to our Telegram channel" },
    //     { id: "id2", description: "Invite 10 friends" },
    //     { id: "id3", description: "Follow us on Twitter" },
    //     { id: "id4", description: "Join our Discord server" },
    //     { id: "id5", description: "Complete our survey" },
    //     { id: "id6", description: "Subscribe to our Instagram and like 5 posts" },
    //     { id: "id7", description: "Join Baboon game on Telegram" },
    //     { id: "daily_id1", description: "Write a message in our group" },
    //     { id: "daily_id2", description: "Chat with Ava" },
    //     { id: "daily_id3", description: "Invite 3 friends" },
    //     { id: "daily_id4", description: "Like 3 posts on Instagram" },
    // ]);

    const [completedQuests, setCompletedQuests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCompletedQuests = async () => {
            try {
                const response = await axios.get(`/api/fetch-completed-quests?telegramId=${telegramId}`);
                setCompletedQuests(response.data.completedQuests);
            } catch (error) {
                console.error('Failed to fetch completed quests:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCompletedQuests();
    }, [telegramId]);

    const notCompletedQuestsCount = TOTAL_QUESTS_COUNT - completedQuests.length;

    const handleClose = () => {
        WebApp.close();
    };

    return (
        <div className={`footer-bar ${fixed ? 'fixed' : ''}`}>
            <div className={`footer-bar-tab ${activeTab === 'home' ? 'active' : ''}`} onClick={() => navigate('/', { state: { referralLink } })}>
                <HomeIcon className='footer-bar-icon home-icon' />
                <p className='footer-bar-text'>Home</p>
            </div>
            <div className={`footer-bar-tab ${activeTab === 'invite' ? 'active' : ''}`} onClick={() => navigate('/invite', { state: { referralLink } })}>
                <UsersIcon className='footer-bar-icon users-icon' />
                <p className='footer-bar-text'>Invite</p>
            </div>
            <div className={`footer-bar-tab ${activeTab === 'chat' ? 'active' : ''}`} onClick={() => {
                sendGreeting(telegramId)
                    .then(() => {
                        handleClose();
                    })
                    .catch((error) => {
                        console.error('Failed to send greeting:', error);
                    });
            }}>
                <HeartIcon className='footer-bar-icon heart-icon' />
                <p className='footer-bar-text'>Chat</p>
            </div>
            <div className={`footer-bar-tab quests ${activeTab === 'quests' ? 'active' : ''}`} onClick={() => navigate('/quests', { state: { referralLink } })}>
                <ListCheckIcon className='footer-bar-icon list-check-icon' />
                <p className='footer-bar-text'>Quests</p>
                {!loading && notCompletedQuestsCount > 0 && (
                    <div className="quests-counter">{notCompletedQuestsCount}</div>
                )}
            </div>
            <div className={`footer-bar-tab ${activeTab === 'nft' ? 'active' : ''}`} onClick={() => navigate('/nft', { state: { referralLink } })}>
                <GemIcon className='footer-bar-icon gem-icon' />
                <p className='footer-bar-text'>NFT</p>
                <div className="nft-notification">NEW</div>

            </div>
        </div>
    );
};

export default FooterBar;
