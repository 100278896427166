import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Invite from './components/Invite';
import Quests from './components/Quests';
import Help from './components/Help';
import NFT from './components/NFT';
import AllNFT from './components/AllNFT';
import NFTHelp from './components/NFTHelp';
import './App.css';
import eruda from 'eruda'


const App = () => {
  // eruda.init()

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/quests" element={<Quests />} />
        <Route path="/help" element={<Help />} />
        <Route path="/nft" element={<NFT />} />
        <Route path="/allnft" element={<AllNFT />} />
        <Route path="/nfthelp" element={<NFTHelp />} />
      </Routes>
    </Router>
  );
};

export default App;
