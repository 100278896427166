// Help.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '@vkruglikov/react-telegram-web-app';

import { ReactComponent as BackIcon } from '../icons/chevron-left-solid.svg';



const NFTHelp = () => {
    const navigate = useNavigate();

    return (
        <div className="App BackgroundImage3">
            <div className="bg-blur">
                <div className="help-page">
                    <div className="header">
                        <div className="header-icon header-icon-button" onClick={() => navigate('/')}>
                            <BackIcon className='header-icon-svg'></BackIcon>
                        </div>
                        <h1>Help</h1>
                        {/* <div className="header-icon header-icon-button" onClick={() => navigate('/allnft')}>
                                    <ToAllIcon className='header-icon-svg'></ToAllIcon>
                                </div> */}
                        <div className="header-icon"></div>
                    </div>
                    <div className="help-container">
                        <div className="help-element">
                            <p className='orange'>🚀 ETHERNITY Airdrop</p>
                            <p>Exchange xETNT for $ETNT after the Q3 2024 launch.</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>🔫 xETNT Mining</p>
                            <p>Mine xETNT until the timer expires.</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>⏱️ Extend Mining</p>
                            <p>Tap bullets to increase mining time.</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>💬 Talk-to-Earn</p>
                            <p>Activate by tapping the heart button and chat with Ava for bonus ❤️ multipliers.</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>🤖 AI Chat</p>
                            <p>Chat with Ava, powered by AI for a lifelike conversation.</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>📸 Request Photos</p>
                            <p>Ask Ava, and receive exclusive snapshots! 🔥</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>🎁 Daily Bonuses</p>
                            <p>Earn up to 5 multipliers daily by talking to Ava.</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>🤝 Invite Friends</p>
                            <p>Get extra xETNT and multipliers by inviting friends.</p>
                        </div>
                        <div className="help-element">
                            <p className='orange'>🏆 Quests</p>
                            <p>Complete quests to gain additional rewards.</p>
                        </div>
                    </div>
                </div>
            </div>
            <BackButton onClick={() => navigate('/')} />
        </div>
    );
};

export default NFTHelp;