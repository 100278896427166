import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { ReactComponent as EthIcon } from '../icons/eth.svg';
import { ReactComponent as MaticIcon } from '../icons/matic.svg';

const NFTPrice = ({ collectionSlug, tokenId, openSeaLink }) => {
    const [price, setPrice] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [error, setError] = useState(null);
    const requestInProgress = useRef(false);

    useEffect(() => {
        const fetchPrice = async () => {
            if (requestInProgress.current) return; // Prevent duplicate requests

            requestInProgress.current = true;
            try {
                console.log('Fetching price for', {
                    collectionSlug,
                    tokenId
                });

                const response = await axios.get(
                    `https://api.opensea.io/api/v2/listings/collection/${collectionSlug}/nfts/${tokenId}/best`,
                    {
                        headers: {
                            'accept': 'application/json',
                            'x-api-key': '4d2a35c476e04fcab0d5d37da71e8198'
                        }
                    }
                );

                console.log('API response:', response);

                if (response.data && response.data.price && response.data.price.current) {
                    const priceInfo = response.data.price.current;
                    console.log('Price Info:', priceInfo);

                    const priceValue = priceInfo.value / Math.pow(10, priceInfo.decimals); // Adjust for decimals
                    const currencySymbol = priceInfo.currency;
                    setPrice(priceValue);
                    setCurrency(currencySymbol);
                } else {
                    console.log('No price information available for the given token.');
                    setPrice('Not for sale');
                }
            } catch (error) {
                console.error('Error fetching price:', error);
                setError('Failed to fetch price');
            } finally {
                requestInProgress.current = false;
            }
        };

        fetchPrice();
    }, [collectionSlug, tokenId]);

    const isListed = price !== null && price !== 'Not for sale';

    return (
        <div>
            <div className={`nft-button-use ${isListed ? 'listed' : ''}`} onClick={() => window.open(openSeaLink, '_blank')}>
                {isListed ? (
                    <>
                        <span>{price} {currency}</span>
                        {currency === 'ETH' && <EthIcon className='nft-price-icon' />}
                        {currency === 'MATIC' && <MaticIcon className='nft-price-icon' />}
                    </>
                ) : (
                    <span>Not for sale</span>
                )}
            </div>
        </div>
    );
};

export default NFTPrice;
